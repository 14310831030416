<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 424.29 93.86"
    :width="`${width}px`"
    :height="`${height}px`"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="29.44"
        y1="78.7"
        x2="29.44"
        y2="-3.14"
        gradientTransform="matrix(1, -0.01, 0.01, 0.95, 1.34, 7.63)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0"
          stop-color="#ec6521"
        />
        <stop
          offset="1"
          stop-color="#e74522"
        />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="85.24"
        y1="80.75"
        x2="85.24"
        y2="15.87"
        xlink:href="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-3"
        x1="137.39"
        y1="86.6"
        x2="137.39"
        y2="19.73"
        xlink:href="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-4"
        x1="187.6"
        y1="78.7"
        x2="187.6"
        y2="4.73"
        xlink:href="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-5"
        x1="229.92"
        y1="80.2"
        x2="229.92"
        y2="1"
        xlink:href="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-6"
        x1="271.52"
        y1="88.69"
        x2="271.52"
        y2="21.81"
        xlink:href="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-7"
        x1="312.55"
        y1="81.48"
        x2="312.55"
        y2="2.29"
        gradientTransform="matrix(1, -0.01, 0.01, 0.95, 1.34, 7.63)"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0"
          stop-color="#53b8e9"
        />
        <stop
          offset="1"
          stop-color="#2e91d1"
        />
      </linearGradient>
      <linearGradient
        id="linear-gradient-8"
        x1="367.45"
        y1="89.69"
        x2="367.45"
        y2="22.94"
        xlink:href="#linear-gradient-7"
      />
      <linearGradient
        id="linear-gradient-9"
        x1="410.69"
        y1="84.29"
        x2="422.06"
        y2="84.29"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlink:href="#linear-gradient-7"
      />
      <linearGradient
        id="linear-gradient-10"
        x1="413.95"
        y1="84.29"
        x2="418.8"
        y2="84.29"
        gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
        xlink:href="#linear-gradient-7"
      />
    </defs>
    <path
      d="M59.16,40.27q-.06,7.41-8.08,9.93a21,21,0,0,1-6.42,1.1q-7.8,0-7.75-6a11,11,0,0,1,3.53-8A13.07,13.07,0,0,1,43.59,35c1.77-1,2.84-1.61,3.2-1.88A1.37,1.37,0,0,0,47.53,32c0-.63-.65-.95-2-1a9.68,9.68,0,0,0-2.81.48q-5.64,1.65-10.28,10A43.09,43.09,0,0,0,28.12,52.2a20.94,20.94,0,0,1,2.2-.17q9.6.06,18.24,7.14,9.78,8,10.14,19.64L35.57,78.5a8.74,8.74,0,0,1-.24-2.39c0-1.29.23-2,.68-2.2a26.63,26.63,0,0,0,3.2-1.43,3,3,0,0,0,1.71-2.61,4.13,4.13,0,0,0-.18-1.13,12.22,12.22,0,0,0-6.3-7.7A17.07,17.07,0,0,0,26.22,59H25.07q0,2.3,2.25,11.65,2,8.31,3.14,11.75a41.64,41.64,0,0,0-9.63-4.83A30.77,30.77,0,0,0,11,75.44q-3.58,0-4.83,1.6l.26-10.6a5,5,0,0,1,2.43-.53,8.67,8.67,0,0,1,4.93,1.79A165.5,165.5,0,0,1,10.2,44Q8.93,30.31,9,19.79c0-.18,0-.58,0-1.22s0-1.15.05-1.57a4,4,0,0,0-1.72-3.31A7.73,7.73,0,0,0,3.2,12.18c-.34,0-.5-1.64-.47-4.83q13.21-.87,26.48-3a141.08,141.08,0,0,0-5.82,38.41q0,4.7.27,8a61.09,61.09,0,0,1,4-11.81Q33.3,26.92,41.77,25.2a20.61,20.61,0,0,1,4-.43,11.27,11.27,0,0,1,8.69,3.66,20.81,20.81,0,0,1,3.78,6.87A14.43,14.43,0,0,1,59.16,40.27Z"
      style="fill:url(#linear-gradient)"
    />
    <path
      d="M114,79c-1-1-2.62-1.5-4.88-1.52a27,27,0,0,0-9.23,1.85,26.08,26.08,0,0,0-8.08,4.38A87.18,87.18,0,0,0,93,73.62c0-.51-.06-.75-.19-.72a1.33,1.33,0,0,0-.42.35,21.72,21.72,0,0,1-12.85,7.52,34.76,34.76,0,0,1-6,.6q-7.28,0-10.41-4.43Q60.35,73,60.39,64.87A85.59,85.59,0,0,1,61,55.54q1.24-10.11,5.22-16.67Q71,31.09,78.4,31.12q8,0,15.47,10.46A32,32,0,0,0,93,35.22a38.58,38.58,0,0,0-2.18-7.14q15.72-3.31,21.74-6.39a29.14,29.14,0,0,1,.54,5.74,6.32,6.32,0,0,1-.4,2.88q-4.71,2.24-4.72,4v.09c0,1,0,2.56.06,4.69s.07,3.78.06,4.92Q108,58.54,105.34,68a7.4,7.4,0,0,1,5-1.82,7.75,7.75,0,0,1,2.84.51ZM95.64,53.69c0-1.27-.88-2.32-2.66-3.18a17.35,17.35,0,0,0-7.39-1.39,22.81,22.81,0,0,0-11.51,3.1q-5.39,3.09-5.41,6.24,0,2.53,3.77,4.08a21.9,21.9,0,0,0,8.1,1.4,17.4,17.4,0,0,0,10.33-3.55C94,58.09,95.63,55.85,95.64,53.69Z"
      style="fill:url(#linear-gradient-2)"
    />
    <path
      d="M165.05,84.2c-.33,1.17-.73,2.64-1.21,4.41l-19.6-5.29q3.34-7.38,3.45-23l0-4.15q-.18-7.39-2.7-7.41a4,4,0,0,0-2.11.93q-5.35,3.77-7.68,16.48,0,4.38,2.83,16.48A27.15,27.15,0,0,0,123.5,78c-3.23,0-5.48.77-6.75,2.36l.14-10.65a4.78,4.78,0,0,1,2.56-.62,8.75,8.75,0,0,1,4.77,1.7q-1.75-3.57-3.1-17.71c-.54-5.83-.9-11-1.08-15.48q-.14-3.62-3-4.3a26.78,26.78,0,0,1-2.81-.65c-.57-.24-.85-1.57-.83-4a10.24,10.24,0,0,1,.14-1.85q.6.45,25.23,2.88-4.19,9.18-4.29,24.21,4-17,14.35-26.37c1.91-1.7,3.57-2.55,5-2.54q3.73,0,5.51,7.16a60.79,60.79,0,0,1,1.39,14.94c0,2.35-.11,4.69-.3,7q-1,12.36-1.05,19,0,6.28.8,8.67Q161.94,82.82,165.05,84.2Z"
      style="fill:url(#linear-gradient-3)"
    />
    <path
      d="M214.3,60a20.25,20.25,0,0,1-1.67,7q-5,12.57-23.78,13.73c-1.81.11-2.87.17-3.19.16q-7.41,0-10.89-3.66-4.28-4.45-4.21-15.46.09-11.69,5.08-28.17a46.64,46.64,0,0,0-10.74,2.29c-.26.05-.43-.14-.51-.59a24.54,24.54,0,0,1,0-2.49,45.3,45.3,0,0,1,.93-9.78,23.22,23.22,0,0,0,10.55,3,62.73,62.73,0,0,0-.78-8.22c-.47-2.92-.7-4.43-.7-4.52,0-.27,0-.43.1-.47a.37.37,0,0,1,.25-.07q2.12,0,9.93-.85t11.86-1.56c.44-.06.68-.06.7,0s-.8,2-2.4,5.68a101.64,101.64,0,0,0-4.26,11.12h2.17c5.43,0,8.22-.37,8.39-1a8.38,8.38,0,0,0,.25-2A7.82,7.82,0,0,0,199,18.58q5.87-4.48,7.88-4.69a11.74,11.74,0,0,1,.58,4.33c0,.81-.05,2-.14,3.56s-.13,2.67-.13,3.3a42.11,42.11,0,0,0,1,7.7,70.71,70.71,0,0,0,2.17,8.56c-.89.6-1.79,1.18-2.69,1.75a7.16,7.16,0,0,1-3.14,1.29,1,1,0,0,1-.85-.37c-.26-.24-.83-1.88-1.73-4.93q-1.28-4.24-2.9-4.43c-.56-.07-3.9-.11-10-.15q-.6.68-1.62,8.66a134.81,134.81,0,0,0-1.23,16.46q-.1,16.07,5,16.09a12,12,0,0,0,6.1-2q3.41-2.06,3.42-3.55a.9.9,0,0,0-.31-.72,35.8,35.8,0,0,1-6.46-3.55,12,12,0,0,1-4.18-9.73,5.67,5.67,0,0,1,2.3-4.63,10.4,10.4,0,0,1,6.62-1.86,25.36,25.36,0,0,1,7.08,1.21Q214.34,53.5,214.3,60Z"
      style="fill:url(#linear-gradient-4)"
    />
    <path
      d="M248.35,80.57a55.62,55.62,0,0,0-13.78-1.74q-7,0-19.75,3.19.51-3.33,1.14-7.76c3.41-.55,5.63-1.69,6.68-3.41s1.93-4.93,2.66-9.62a85.39,85.39,0,0,0,1.12-13c0-4.51-1.31-6.77-4-6.79a7.29,7.29,0,0,0-2.43.4,29.19,29.19,0,0,1-1.7-7.82,53.14,53.14,0,0,0,24.49-6,1.42,1.42,0,0,1,.12.77,36.15,36.15,0,0,1-.35,3.6A217.84,217.84,0,0,0,240.32,59q0,7.35,1.18,9.62A2.4,2.4,0,0,0,243.66,70a7.43,7.43,0,0,0,3.65-1.2c.38-.21.61,0,.66.77C248.19,72.18,248.31,75.85,248.35,80.57Zm.17-64c0,2-1.66,3.72-5,5.11a22.65,22.65,0,0,1-9.2,1.85A11.23,11.23,0,0,1,227,21.12q-3.33-2.82-3.3-8,0-6.76,11.09-6.71a15.63,15.63,0,0,1,10.1,3.26Q248.55,12.62,248.52,16.59Z"
      style="fill:url(#linear-gradient-5)"
    />
    <path
      d="M299.19,84.9c-.32,1.17-.73,2.64-1.21,4.42L278.39,84q3.33-7.38,3.44-23l0-4.15c-.12-4.94-1-7.41-2.69-7.42a4,4,0,0,0-2.12.94q-5.35,3.76-7.67,16.48,0,4.37,2.82,16.48a27.12,27.12,0,0,0-14.55-4.68q-4.86,0-6.75,2.36L251,70.39a4.83,4.83,0,0,1,2.56-.62,8.73,8.73,0,0,1,4.78,1.7q-1.77-3.59-3.11-17.71-.8-8.76-1.07-15.48c-.09-2.41-1.11-3.85-3-4.31a23.86,23.86,0,0,1-2.8-.64c-.58-.25-.85-1.57-.84-4a11.35,11.35,0,0,1,.14-1.85q.6.46,25.23,2.89-4.19,9.18-4.28,24.21,4-17,14.35-26.37c1.9-1.71,3.57-2.55,5-2.55q3.74,0,5.51,7.16a61.36,61.36,0,0,1,1.4,14.95c0,2.34-.12,4.69-.3,7q-1,12.36-1.06,19,0,6.27.8,8.67Q296.08,83.54,299.19,84.9Z"
      style="fill:url(#linear-gradient-6)"
    />
    <path
      d="M331,81a54.63,54.63,0,0,0-13.78-1.74q-7.05,0-19.75,3.19c.34-2.23.72-4.81,1.14-7.76q5.12-.83,6.68-3.42t2.66-9.62a85.26,85.26,0,0,0,1.12-13c0-4.51-1.31-6.78-4-6.79a7,7,0,0,0-2.43.39,29.55,29.55,0,0,1-1.7-7.81,53.28,53.28,0,0,0,24.49-6,1.45,1.45,0,0,1,.12.77c0,.66-.12,1.87-.35,3.61A217.69,217.69,0,0,0,323,59.4q0,7.35,1.18,9.61a2.37,2.37,0,0,0,2.16,1.41,7.23,7.23,0,0,0,3.65-1.2c.38-.2.61,0,.67.78C330.82,72.61,331,76.28,331,81Zm.17-64c0,2-1.66,3.72-4.95,5.12A22.82,22.82,0,0,1,317,24a11.36,11.36,0,0,1-7.42-2.43q-3.33-2.82-3.3-8,0-6.78,11.09-6.71a15.63,15.63,0,0,1,10.1,3.25Q331.17,13.05,331.15,17Z"
      style="fill:url(#linear-gradient-7)"
    />
    <path
      d="M408.47,48.2q-.09,13-11.76,25.75l8.7,2c.44.84,1,2.17,1.79,4-1.65-.13-4.75-.21-9.28-.23q-6.29,0-13.66.11,2.36-3.83,5-12.61,2.91-9.55,2.94-15,0-4.6-2.2-4.62-3.64,0-7.82,8.63a78.77,78.77,0,0,0-5.53,15.9c0,6,.21,9.75.75,11.28a25.07,25.07,0,0,0,4.33,1.69c-.24.58-.66,2-1.27,4.42l-17.36-5.73q3.12-8.48,3.21-21.69,0-6-.11-7.58C365.9,50.84,365,49,363.62,49a3.92,3.92,0,0,0-2.08.89q-5.32,3.72-7.84,17.24,0,4.29,2.83,16.3a27,27,0,0,0-14.94-4.54q-5.26,0-7.26,2.4l.2-10.47A5.15,5.15,0,0,1,337,70.3a8.72,8.72,0,0,1,4.74,1.51q-2.61-9.12-4.6-31.56c-.2-2-1-3.21-2.4-3.58-1.3-.1-2.59-.21-3.86-.34q-1.18-.22-1.15-5.51h.67q3.65,0,25.61-1.54a66.39,66.39,0,0,0-3.53,21.78,43.3,43.3,0,0,0,.16,4.42q3.91-17.67,14.71-27.09,2.82-2.46,4.9-2.45,6,0,5.84,18.89,0,5.55-.48,10a63.37,63.37,0,0,1,6.82-12.55q6.46-8.91,13.33-8.87a9.27,9.27,0,0,1,7.7,4.17A17.36,17.36,0,0,1,408.47,48.2Z"
      style="fill:url(#linear-gradient-8)"
    />
    <circle
      cx="416.38"
      cy="84.29"
      r="5.18"
      style="fill:none;stroke-miterlimit:10;stroke:url(#linear-gradient-9)"
    />
    <path
      d="M417.67,87.12l-1.16-1.66H415v1.65H414V81.45h2.34a3.14,3.14,0,0,1,1.28.24,1.83,1.83,0,0,1,.84.7,1.9,1.9,0,0,1,.3,1.08,1.92,1.92,0,0,1-.32,1.1,1.78,1.78,0,0,1-.89.69l1.3,1.86Zm-.38-4.49a1.66,1.66,0,0,0-1.05-.29H415V84.6h1.23a1.66,1.66,0,0,0,1.05-.29,1.18,1.18,0,0,0,0-1.68Z"
      style="fill:url(#linear-gradient-10)"
    />
  </svg>
</template>
<script>
export default {
  props: {
    height: { type: Number, default: 25 },
    width: { type: Number, default: 40 },
  },
}
</script>
